// frontend/src/components/DeckMemorizationGame.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import './DeckMemorizationGame.css'; // Add CSS for card styling

const DeckMemorizationGame = () => {
  const { gameId } = useParams();
  const [gameState, setGameState] = useState(null);
  const [guesses, setGuesses] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchGameState = async () => {
      try {
        const gameDoc = await getDoc(doc(db, 'games', gameId));
        if (gameDoc.exists()) {
          setGameState(gameDoc.data());
        } else {
          console.error('Game not found');
        }
      } catch (error) {
        console.error('Error fetching game state:', error);
      }
    };

    fetchGameState();
  }, [db, gameId]);

  const startGuessingPhase = async () => {
    try {
      await updateDoc(doc(db, 'games', gameId), { state: 'guessing' });
      setGameState((prevState) => ({ ...prevState, state: 'guessing' }));
    } catch (error) {
      console.error('Error starting guessing phase:', error);
    }
  };

  const handleGuessChange = (index, value) => {
    const newGuesses = [...guesses];
    newGuesses[index] = value;
    setGuesses(newGuesses);
  };

  const submitGuess = async () => {
    try {
      await updateDoc(doc(db, 'games', gameId), { guesses, state: 'completed' });
      setGameState((prevState) => ({ ...prevState, state: 'completed' }));
    } catch (error) {
      console.error('Error submitting guesses:', error);
    }
  };

  if (!gameState) {
    return <div>Loading...</div>;
  }

  if (gameState.state === 'remembering') {
    return (
      <div>
        <h2>Remember the Deck</h2>
        <div className="deck">
          {/* Display the deck here */}
          {gameState.deck.map((card, index) => (
            <div key={index} className="card">
              {card}
            </div>
          ))}
        </div>
        <button onClick={startGuessingPhase}>Start Guessing</button>
      </div>
    );
  }

  if (gameState.state === 'guessing') {
    return (
      <div>
        <h2>Guess the Order</h2>
        <div className="guesses">
          {Array.from({ length: 52 }).map((_, index) => (
            <input
              key={index}
              type="text"
              placeholder={`Card ${index + 1}`}
              value={guesses[index] || ''}
              onChange={(e) => handleGuessChange(index, e.target.value)}
            />
          ))}
        </div>
        <button onClick={submitGuess}>Submit Guess</button>
      </div>
    );
  }

  if (gameState.state === 'completed') {
    return (
      <div>
        <h2>Game Over</h2>
        {/* Display results and leaderboard */}
      </div>
    );
  }

  return null;
};

export default DeckMemorizationGame;
