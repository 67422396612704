// frontend/src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FirebaseAuth from './components/FirebaseAuth';
import GameList from './components/GameList';
import DeckMemorizationGame from './components/DeckMemorizationGame';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<FirebaseAuth />} />
        <Route path="/games" element={<GameList />} />
        <Route path="/deck-memorization/:gameId" element={<DeckMemorizationGame />} />
        <Route path="/" element={<GameList />} />
      </Routes>
    </Router>
  );
}

export default App;
