// frontend/src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {

  apiKey: "AIzaSyCZsKxg4MFR6GZTo21TzD5hGuJm19jUOws",

  authDomain: "lokispalace-a6939.firebaseapp.com",

  projectId: "lokispalace-a6939",

  storageBucket: "lokispalace-a6939.appspot.com",

  messagingSenderId: "35607096264",

  appId: "1:35607096264:web:bd407d36b949f0f37a6efd",

  measurementId: "G-SM6P5VNG5R"

};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
