import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

function GameList() {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const gamesCollection = collection(db, 'games');
        const gameSnapshot = await getDocs(gamesCollection);
        const gameList = gameSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setGames(gameList);
      } catch (error) {
        console.error('Error fetching games:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  const handleGameClick = (gameId) => {
    navigate(`/deck-memorization/${gameId}`);
  };

  if (loading) {
    return <div>Loading games...</div>;
  }

  return (
    <div>
      <h2>Game List</h2>
      <ul>
        {games.map((game) => (
          <li key={game.id} onClick={() => handleGameClick(game.id)}>
            {game.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GameList;
